@import "./App";

.keyboard-container {
  width: 100%;
  position: relative;

  button {
    font-size: 28px;
    border: solid 1px $blue100-primary;
    background-color: $primary-white;
    color: $blue100-primary;
    border-radius: 6px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease;
    cursor: pointer;
    width: 9%;
    margin: .5%;
    position: relative;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &.primary-btn {
      background-color: $blue100-primary;
      color: $primary-white;
      border-color: $blue100-primary;
    }

    &.btn-widekey {
      width: 18%;

      &:after {
        padding-bottom: 50%;
      }
    }

    &:active, &:hover {
      background-color: $purple60-primary;
      border-color: $purple60-primary;
      color: $primary-white;
    }
  }
}
