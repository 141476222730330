// Color definitions
$blue100-primary: #2A355B;
$orange60: #EE662F;
$orange10: #FFF8F4;
$purple60-primary: #7A46A3;
$primary-white: #FFF;

* {
  outline: none;
}

body, #root {
  height: 100%;
}

.aio-page {
  background-color: $orange10 !important;
}

.aio-page-main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.aio-body {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 20px;
  flex: 1;
}

.main {
  width: 80vh;
  max-width: 800px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .row {
    max-width: 100vh;
    min-width: 500px;
    margin: 0 auto;
  }
}

.responsive-spacer {
  flex: 1;
  min-height: 10px;
}

#wizard-toolbar {
  .toolbar {
    max-height: 8vh !important;
    min-height: 60px !important;

    a.wizard-desktop-logo {
      position: relative;
      height: 34px;
      max-height: 100%;
      top: 16px;
    }
  }
}

.btn-primary {
  min-width: 10vh;
}

h1 {
  font-size: 4vh !important;
}

h3 {
  font-weight: normal !important;
  font-size: 2vh !important;
}

.vehicle-reg-input {
  max-width: 300px;
  text-align: center;
  font-size: 3vh !important;
  background-color: $primary-white !important;
  height: 5vh !important;
  min-height: 40px;
  border-bottom: 2px solid $blue100-primary !important;
  color: $blue100-primary !important;
  font-weight: 400 !important;
  -webkit-text-fill-color: $blue100-primary !important;
  opacity: 1 !important;
}

.dropdown-box {
  overflow: auto !important;
}

.message {
  max-width: none !important;
}

.margin-right {
  margin-right: 5vw;
}

.vehicle-reg-container {
  display: flex;
  justify-content: center;
  position: relative;

  .aio-icon, .aio-icon-xl {
    width: 7vh;
    height: 7vh;
    position: absolute;
    top: -20%;
    left: -10%;
  }

  .vehicle-reg {
    color: $blue100-primary;
    padding: 0 6vh;
    height: fit-content;
    font-size: 6vh;
    background: $primary-white;
    border: 4px solid $blue100-primary;
    border-radius: 8px;
  }
}

// This is an empty div to avoid footer from overlapping the keyboard
.footer-backdrop {
  padding: 20px;

  > div {
    height: 4vh;
  }

  @media screen and (max-height: 700px) and (min-width: 1000px) {
    display: none;
  }
}

.operator-footer {
  background-color: $orange10 !important;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100vw;

  img {
    max-height: 4vh;
  }

  @media screen and (max-height: 700px) and (min-width: 1000px) {
    position: relative;
    height: 10vh;
  }
}


.parking-validated-title span {
  color: $orange60;
}

.parking-validated-subtitle span {
  font-weight: bold;
}

.vehicle-characteristics {
  display: flex;
  justify-content: center;

  .vehicle-type {
    font-weight: bold;
    line-height: 1.1;
    font-size: 1.5rem;
  }

  .vehicle-color {
    margin: 0 7px 0 40px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border: 2px solid $blue100-primary;
    display: inline-block;
  }

  .split-circle {
    background: linear-gradient(-45deg, $blue100-primary, $blue100-primary 49%, $primary-white 51%);
  }
}
